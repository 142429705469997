@import "/usr/src/app/node_modules/@dws-contributes/sitespro-gatsby-base/src/config";
footer {
    background: var(--sp-color-footer-primary) no-repeat 950px bottom;
    // background: url("./../../../images/arch_pattern.svg") var(--sp-color-footer-primary) no-repeat 950px bottom;
    background-color: var(--sp-color-footer-primary);
    margin-bottom: -10px;
    margin-top: 80px;
    padding: $spacer*2;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $white;
    font-family: var(--sp-font-secondary);
  
      @include media-breakpoint-up(md) {
          padding: $spacer*3 $spacer $spacer*2 $spacer;
      }
  
    a {
      color: $white;
      &:hover {
        text-decoration: underline;
        color: $white;
      }
    }
  
    .site_name {
      font-size: 26px;
      font-weight: 600;
      margin-bottom: $spacer;
      margin-top: 0;
      text-transform: none;
  
      @include media-breakpoint-up(md) {
        margin-bottom: $spacer*2;
      }
  
      a {
        color: var(--sp-color-footer-logo);
        text-decoration: none;
      }
    }
  
    .footer__address,
    .footer__email,
    .footer__phone,
    .footer-contact p {
      position: relative;
      padding-left: 32px;
  
      span {
        display: block;
      }
  
      &:before {
        content: "";
        display: block;
        height: 24px;
        width: 24px;
        position: absolute;
        top: 3px;
        left: 0;
      }
  
      svg {
        color: var(--sp-color-accent-one);
        width: 24px;
        height: 24px;
        position: absolute;
        left: 0;
      }    
    }
  
    .footer__right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      > :first-child {
        @include media-breakpoint-up(md) {
          margin-top: 0;
        }
      }
    }
  
    .heading {
      color: $white;
      font-size: 1rem;
      margin-bottom: 0;
      font-weight: 700;
    }
  
    .footer__social-icons-wrapper {
      margin-top: $spacer;
      display: flex;
  
      @include media-breakpoint-up(md) {
        text-align: center;
      }
  
      a {
        height: 46px;
        width: 46px;
        padding: 7px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: var(--sp-color-footer-social);
        margin: 0.5em 1em 0.5em 0;
        border-radius: 50%;
  
        @include media-breakpoint-up(md) {
          margin: 0.5em 1em 0.5em 0;
        }
  
        &:after {
          display: none !important;
        }
  
        &:hover {
          background-color: var(--sp-color-accent-two);
          transition: background-color 0.2s ease-out;
        }
  
        svg {
          width: 100%;
          height: 100%;
          color: #fff;
  
          &:before {
            content: '';
            border-radius: 50%;
            background-color: #fff;
            width: 100%;
            height: 100%;
            position: absolute;
          }
        }
  
        &[href*="facebook"] {
          padding-top: 8px;
          padding-bottom: 0;
        }
  
        &[href*="twitter"] {
          padding: 10px;
        }   
  
        &[href*="linkedin"] {
          padding: 9px;
        }            
      }
    }
  
    .separator {
      height: 1px;
      background-color: var(--sp-color-secondary);
      border: none;
      margin: 2em 0;
  
      @media (max-width: 768px) {
        height: 0px;
      }
    }
  
    .footer__copyright-links-wrapper {
      a {
        color: $white;
        font-size: 14px;
        text-decoration: none;
        margin-right: 2em;
  
        &:hover {
          color: #fff;
          text-decoration: underline;
        }
      }
    }
  
    .col-md-4 {
      @include media-breakpoint-down(sm) {
        &:nth-child(1) {
          border-bottom: 1px solid var(--sp-color-secondary);
                  padding-bottom: $spacer;
        }
        &:nth-child(3) {
          border-top: 1px solid var(--sp-color-secondary);
                  padding-top: $spacer;
        }
      }
    }
  
    .copyrightWrapper {
      border-top: 1px solid var(--sp-color-secondary);
          display: flex;
      align-items: center;
          padding-top: $spacer*2;
  
      @include media-breakpoint-up(md) {
        border-top: none;
        padding-top: 0;
      }
    }
  
    .footer__copyright-Drupallinks-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }
  
  